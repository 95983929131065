import "./Sale.scss";
import image from "../../assets/images/gallery2small.webp";

function Sale() {
  return (
    <>
      <div id="location" className="sale">
        <div className="sale__locations">
          <h2 className="sale__heading">Locations</h2>
          <p className="sale__heading--copy">
            Enhance the experience of your business environment with our
            convenient vending machine solutions. Whether you have a bustling
            office with employees or welcome clients to your space, our vending
            machines offer a seamless way to provide refreshments and snacks.
            Located in the lower mainland? Enjoy the added convenience of free
            delivery straight to your doorstep. Elevate your workplace or client
            experience today with our reliable vending machine services
          </p>
          <br/>
          <p className="sale__heading--copy">
            We also sell vending machines strategically placed in high-traffic, profitable
            locations. Join the winning side of commerce by investing in our
            sought-after vending solutions. It's not just vending machines; it's
            a pathway to profit!
          </p>
        </div>
        <div className="sale__cards">
          <div className="sale__card">
            <img src={image} alt="Vending machine, vancouver, bc, surrey memorial hospital" className="sale__image"></img>
            <div className="sale__copy">
              <div>
                <h3 className="sale__copy--heading">Location</h3>
                <p className="sale__copy--copy">Surrey Memorial</p>
                <p className="sale__copy--copy">
                  Located in the ambulance bay{" "}
                </p>
              </div>
              <div>
                <h3 className="sale__copy--heading">Vending Machine</h3>
                <p className="sale__copy--copy">
                  Snack and Drink Combo vending Machine
                </p>
              </div>
              <div>
                <h3 className="sale__copy--heading">Snacks</h3>
                <p className="sale__copy--copy">
                  Healthy snacks, responsible for fueling first responders and
                  doctors at the hospital
                </p>
              </div>
              <h3 className="sale__copy--sold">SOLD</h3>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Sale;
