import { useState } from "react";
import pirhana from "../../assets/images/pirhana.webp";
import max from "../../assets/images/max2.webp";
import nayax from "../../assets/images/nayax.webp";
import xy from "../../assets/images/yx.webp";
import bigtouch from "../../assets/images/bigtouch.webp";
import newcombo from "../../assets/images/newcombo.webp";
import smallcombo from "../../assets/images/touchscreen.webp";
import tcn from "../../assets/images/tcn.webp";
import xysmall from "../../assets/images/xysmall.webp";
import xysle from "../../assets/images/xysle10c.webp";
import xydle from "../../assets/images/xydle.webp";
import xydle8 from "../../assets/images/xydle8.webp";
import "./Equipment.scss";

function Equipment() {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <div id="equipment" className="equipment">
        <h2 className="equipment__heading">Equipment</h2>
        <div className="equipment__container">
          <div className="equipment__card">
            <img src={pirhana} alt="" className="equipment__image" />

            <div className="equipment__description">
              <h3 className="equipment__details">Cold Drink Vending Machine</h3>
              <p className="equipment__description--details">
                24″ W x 32″ D x 75″ H, 475LBS
              </p>
              <p className="equipment__description--details">
                25 Drink Selections
              </p>
              <p className="equipment__description--details">175 Drink Items</p>
              <p className="equipment__description--details">Can & Bottles</p>
              <p className="equipment__description--details">1 Year Warranty</p>
            </div>
          </div>

          <div className="equipment__card">
            <img src={max} alt="" className="equipment__image" />
            <div className="equipment__description">
              <h3 className="equipment__details">
                Snack and Drink Combo Machine
              </h3>
              <p className="equipment__description--details">
                34.5″ W x 29.6″ D x 72″ , 600LBS
              </p>
              <p className="equipment__description--details">
                230 Item Capacity
              </p>
              <p className="equipment__description--details">
                18 Snack Items & 18 Beverage Items
              </p>
              <p className="equipment__description--details">Can & Bottles</p>
              <p className="equipment__description--details">1 Year Warranty</p>
            </div>
          </div>

          <div className="equipment__card">
            <img src={xy} alt="" className="equipment__image" />

            <div className="equipment__description">
              <h3 className="equipment__details">
                Snack and Drink Combo Machine
              </h3>
              <p className="equipment__description--details">
                34″ W x 29″ D x 76″ H, 485LBS
              </p>
              <p className="equipment__description--details">
                300-600 Item Capacity
              </p>
              <p className="equipment__description--details">
                2&deg;C-25&deg;C
              </p>
              <p className="equipment__description--details">Can & Bottles</p>
              <p className="equipment__description--details">1 Year Warranty</p>
            </div>
          </div>

          <div className="equipment__card">
            <img src={nayax} alt="" className="equipment__image" />

            <div className="equipment__description">
              <h3 className="equipment__details">Nayax Payment Soluntions</h3>
              <p className="equipment__description--details">
                Revolutionizing transactions with seamless payment solutions.
                Accept cards, mobile payments, and more, all with top-notch
                security and real-time monitoring. Elevate your business with
                Nayax.
              </p>
              <p className="equipment__description--details">1 Year Warranty</p>
            </div>
          </div>
        </div>
        {showMore && (
          <div className="equipment__hidden">
            <div className="equipment__card">
              <img src={tcn} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Mini Hot Food Vending Machine
                </h3>
                <p className="equipment__description--details">
                  27″ W x 26″ D x 78″ H, 485LBS
                </p>
                <p className="equipment__description--details">
                  24 Item Capacity
                </p>
                <p className="equipment__description--details">
                  4&deg;C - 65&deg;C
                </p>
                <p className="equipment__description--details">Hot Food</p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>

            <div className="equipment__card">
              <img src={bigtouch} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Snack and Drink Combo Machine with 32" Touch Screen
                </h3>
                <p className="equipment__description--details">
                  53″ W x 33″ D x 75″ H, 771LBS
                </p>
                <p className="equipment__description--details">
                  300 - 900 Item Capacity
                </p>
                <p className="equipment__description--details">
                  2&deg;C - 25&deg;C
                </p>
                <p className="equipment__description--details">
                  Touch screen can also play advertising videos
                </p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>

            <div className="equipment__card">
              <img src={smallcombo} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Snack and Drink Vending Machine with 21.5" Touch Screen
                </h3>
                <p className="equipment__description--details">
                  46″ W x 33″ D x 75″ H, 485LBS
                </p>
                <p className="equipment__description--details">
                  300 - 800 Item Capacity
                </p>
                <p className="equipment__description--details">
                  2&deg;C - 25&deg;C
                </p>
                <p className="equipment__description--details">
                  Touch Screen can also play advertising videos
                </p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>

            <div className="equipment__card">
              <img src={newcombo} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Snack and Drink Combo Vending Machine
                </h3>
                <p className="equipment__description--details">
                  46″ W x 33″ D x 76″ H, 485LBS
                </p>
                <p className="equipment__description--details">
                  300 - 900 Item Capacity
                </p>
                <p className="equipment__description--details">
                  2&deg;C - 25&deg;C
                </p>
                <p className="equipment__description--details">
                  10.1" Touch Screen
                </p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>

            <div className="equipment__card">
              <img src={xysmall} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Snack and Drink Combo Vending Machine, to sell cold and room
                  temperature beverages
                </h3>
                <p className="equipment__description--details">
                  29″ W x 24″ D x 76″ H, 507LBS
                </p>
                <p className="equipment__description--details">
                  300 - 600 Item Capacity
                </p>
                <p className="equipment__description--details">
                  2&deg;C - 25&deg;C
                </p>
                <p className="equipment__description--details">
                  5" Touch Screen
                </p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>

            <div className="equipment__card">
              <img src={xysle} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Snack and Drink Combo Vending Machine with lifting system form
                  cold or room temperature beverages and snacks
                </h3>
                <p className="equipment__description--details">
                  46″ W x 33″ D x 76″ H, 661LBS
                </p>
                <p className="equipment__description--details">
                  300 - 900 Item Capacity
                </p>
                <p className="equipment__description--details">
                  2&deg;C - 25&deg;C
                </p>
                <p className="equipment__description--details">
                  5" Touch Screen
                </p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>

            <div className="equipment__card">
              <img src={xydle} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Snack and Drink Combo Vending Machine, to sell cold and room
                  temperature beverages and snacks
                </h3>
                <p className="equipment__description--details">
                  46″ W x 33″ D x 76″ H, 683LBS
                </p>
                <p className="equipment__description--details">
                  300 - 600 Item Capacity
                </p>
                <p className="equipment__description--details">
                  2&deg;C - 25&deg;C
                </p>
                <p className="equipment__description--details">
                  5" Touch Screen
                </p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>

            <div className="equipment__card">
              <img src={xydle8} alt="" className="equipment__image" />

              <div className="equipment__description">
                <h3 className="equipment__details">
                  Snack and Drink Combo Vending Machine, to sell cold and room
                  temperature beverages
                </h3>
                <p className="equipment__description--details">
                  39″ W x 33″ D x 75″ H, 617LBS
                </p>
                <p className="equipment__description--details">
                  300 - 800 Item Capacity
                </p>
                <p className="equipment__description--details">
                  2&deg;C - 25&deg;C
                </p>
                <p className="equipment__description--details">
                  5" Touch Screen
                </p>
                <p className="equipment__description--details">
                  1 Year Warranty
                </p>
              </div>
            </div>


          </div>
        )}
        <button className="equipment__more" onClick={handleToggle}>
          {showMore ? "Show Less" : "Show More"}
        </button>
      </div>
    </>
  );
}

export default Equipment;
